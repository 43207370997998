<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dense>
        <v-btn icon @click.native="cancel">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-toolbar-title>{{ $t('booking') }}</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text>
        <v-container fluid grid-list-md pa-0>
          <v-layout row wrap>
            <v-flex xs6c>
              <div class="top-box">
                <b class="n-orange"> 1 </b>
                <b style="color: #000">{{ $t('booking-form') }}</b>
              </div>
            </v-flex>
            <v-flex xs6>
              <div class="top-box">
                <span class="n-gray"> 2 </span>
                <span style="color: #aaa">{{ $t('booking-confirmation') }}</span>
              </div>
            </v-flex>
          </v-layout>

          <img class="img--hero" :src="post.images[0].original" alt="" />
          <!-- <v-carousel class="carousel-img" hide-delimiters >
            <v-carousel-item v-for="(image,i) in post.images"  :key="i">
              <img class="img" :src="image.original" alt="" @click="dialog = true">
            </v-carousel-item>
          </v-carousel> -->
          <!-- <v-carousel hide-delimiters height="auto">
            <v-carousel-item
              v-for="(image,i) in post.images"
              :key="i"
              :src="image.original"
              reverse-transition="fade"
              transition="fade"
              @click="dialog = true"
            ></v-carousel-item>
          </v-carousel> -->
          <span class="post-name">{{ post.name }}</span>
          <table style="color: #aeaeae">
            <tr>
              <td>{{ $t('duration') }}</td>
              <td>{{ $t('post-duration', [post.duration]) }}</td>
            </tr>
            <tr>
              <td>{{ $t('location') }}</td>
              <td>{{ $t('post-location', [post.location]) }}</td>
            </tr>
            <tr>
              <td>{{ $t('product-id') }}</td>
              <td>{{ $t('post-code', [post.code]) }}</td>
            </tr>
          </table>
          <br />
          <span class="title_bg">{{ $t('booking-detail') }}</span>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-dialog
              ref="dialog"
              v-model="date_picker"
              :return-value.sync="date"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date"
                  :label="$t('visit-date')"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="date" :min="today" scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="date_picker = false">
                  {{ $t('cancel') }} </v-btn>
                <v-btn text color="primary" @click="$refs.dialog.save(date)">
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
            <v-select
              :items="post.packages"
              item-text="name"
              item-value="products"
              v-model="priceItem"
              :placeholder="$t('please-select-package')"
              :label="$t('package-options')"
            >
            </v-select>
            <div class="text-right">
              <v-icon size="10" color="deep-orange">mdi-star</v-icon> {{ $t('please-select-one-package-are-you-want-to-book') }} </div>
            <span class="title_bg">{{ $t('partisipan-quantity') }}</span>
            <div v-if="priceItem">
              <packs :items="priceItem" @total="total"></packs>
              <div class="text-right">
                <v-icon size="10" color="deep-orange">mdi-star</v-icon> {{ $t('please-fill-total-participan-quantity-who-will-joining-and-other-package-optional') }} </div>
            </div>
            <div v-else>
              <span class="body-2">{{ $t('please-select-package-above') }}</span>
            </div>
            <div v-if="!user">
              <span class="title_bg" @click="loginDialog = true"
                >{{ $t('login-signup') }}</span
              ><br />
              <login-dialog
                :dialog="loginDialog"
                @loginSuccess="loginSuccess"
                @cancel="loginDialog = false"
              ></login-dialog>
            </div>
            <span class="title_bg">{{ $t('your-personal-information') }}</span>
            <v-select
              v-model="title"
              :items="titles"
              :placeholder="$t('please-select')"
              required
              :label="$t('title')"
            ></v-select>
            <v-text-field
              v-model="first_name"
              :rules="fieldRequired"
              :label="$t('first-name')"
              :placeholder="$t('please-enter-first-name')"
              required
            ></v-text-field>
            <v-text-field
              v-model="last_name"
              :rules="fieldRequired"
              :label="$t('last-name')"
              :placeholder="$t('please-enter-last-name')"
              required
            ></v-text-field>
            <v-text-field
              v-model="email"
              :rules="emailRules"
              :label="$t('e-mail-address')"
              :placeholder="$t('to-receive-booking-detail')"
              required
            ></v-text-field>
            <v-select
              :items="countries"
              v-model="country"
              :placeholder="$t('please-select-your-country')"
              :label="$t('country')"
            ></v-select>
            <v-text-field
              v-model="phone"
              :rules="fieldRequired"
              :label="$t('phone-number')"
              :placeholder="$t('in-case-of-emergency')"
              required
            ></v-text-field>
            <v-textarea
              v-model="special_request"
              :label="$t('special-request')"
            ></v-textarea>
            <div class="text-right">
              <v-icon size="10" color="deep-orange">mdi-star</v-icon> {{ $t('please-write-if-you-have-any-special-request') }} </div>

            <span class="title_bg">{{ $t('pickup-information') }}</span>
            <v-text-field
              v-model="pickup"
              :rules="fieldRequired"
              :placeholder="$t('detail-name-and-address-pickup-location')"
              hint="Kindly to share your location with google maps to easy pickup process"
              required
              persistent-hint
              :label="$t('pickup-location')"
            ></v-text-field>
            <span class="title_bg">{{ $t('payment-methode') }}</span>
            <v-radio-group v-model="paymentMethod">
              <div v-for="item in paymentMethods" :key="item.name">
                <v-layout row wrap mt-1>
                  <v-flex xs2>
                    <v-radio :key="item.name" :value="item.name"></v-radio>
                  </v-flex>
                  <v-flex xs2>
                    <img :src="item.icon" width="25px" height="25px" alt="" />
                  </v-flex>
                  <v-flex xs8>
                    {{ item.name }}
                  </v-flex>
                </v-layout>
              </div>
            </v-radio-group>

            <h4>{{ $t('common-questions') }}</h4>
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-content v-for="(item, i) in qa" :key="i">
                  <template v-slot:header>
                    <div>{{ item.q }}</div>
                  </template>
                  <v-card>
                    <v-card-text>{{ item.a }}</v-card-text>
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <div class="red-box">
              <b
                ><v-icon color="black" size="18">mdi-lock</v-icon> {{ $t('data-security') }}y</b
              ><br />
              <span
                >{{ $t('your-data-are') }}.</span
              >
            </div>

            <div class="checkout">
              <v-icon color="deep-orange" size="12">mdi-star</v-icon> {{ $t('click') }}
              <span style="color: #ff6100"> "CHECKOUT"</span> {{ $t('to-view-your-final-booking-detail') }} </div>
            <div class="bottom--booking sticky">
              <v-layout row wrap>
                <v-flex xs6>
                  <small>{{ $t('total') }}</small><br />
                  <span class="title deep-orange--text text-darken-1">
                    {{ $root.$i18n.locale == "id" ? "IDR " : "USD " }}
                    {{ totalOrder | fm }}</span
                  >
                </v-flex>
                <v-flex xs6 class="text-xs-right">
                  <v-btn
                    dark
                    class="float-right mt-2 mr-8"
                    color="blue-grey darken-3 white--text"
                    @click="submit"
                  >
                    {{ $t('checkout') }} </v-btn>
                  <!-- <button type="submit" class="btn--button" @click.native.prevent="submit" form="booking-form-id">
                    Book now
                  </button> -->
                </v-flex>
              </v-layout>
            </div>
          </v-form>
          <v-snackbar v-model="snackbar" :color="snack_color">
            {{ snack_text }}
            <template v-slot:action="{ attrs }">
              <v-btn text @click="snackbar = false" v-bind="attrs">
                {{ $t('close') }} </v-btn></template
            >
          </v-snackbar>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
/* eslint-disable */
export default {
  props: ["dialog","type", "post"],

  data() {
    return {
      user: false,
      loginDialog: false,
      valid: false,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substring(0, 10),
      priceItem: null,
      adults: 1,
      kids: 0,
      title: "Mr.",
      email: null,
      first_name: null,
      last_name: null,
      country: this.$t('australia'),
      phone: null,
      special_request: null,
      pickup: null,
      paymentMethod: this.$t('cash-money'),
      paymentMethods: [
        {
          name: this.$t('cash-money'),
          icon: require("@/assets/images/payment_icon/cash_xs.webp"),
          hint: "",
        },
        {
          name: this.$t('paypal'),
          icon: require("@/assets/images/payment_icon/paypal_xs.webp"),
          hint: this.$t('request-by-email'),
        },
        {
          name: this.$t('credit-card'),
          icon: require("@/assets/images/payment_icon/card_xs.webp"),
          hint: this.$t('request-by-email'),
        },
        {
          name: this.$t('bank-transfer'),
          icon: require("@/assets/images/payment_icon/bank_xs.webp"),
          hint: this.$t('request-by-email'),
        },
      ],
      qa: [
        {
          q: "How do i pay?",
          a: "You can pay cash, use a credit or debit card, and PayPal. There will be an instruction on how to do the payment in the email you receive.",
        },
        {
          q: "Can i pay by cash?",
          a: "You are welcome to pay cash. For Bali Tour Combination you can pay to our drivers.",
        },
        {
          q: "Is my payment secure?",
          a: "Our online payment system is safe. It encrypts the information of your payment to prevent you from unauthorized transactions and from the frauds.",
        },
      ],
      titles: ["Mr.", "Mrs.", "Ms."],
      countries: [
        this.$t('afghanistan'),
        this.$t('aland-islands'),
        this.$t('albania'),
        this.$t('algeria'),
        this.$t('american-samoa'),
        this.$t('andorra'),
        this.$t('angola'),
        this.$t('anguilla'),
        this.$t('antarctica'),
        this.$t('antigua-and-barbuda'),
        this.$t('argentina'),
        this.$t('armenia'),
        this.$t('aruba'),
        this.$t('australia'),
        this.$t('austria'),
        this.$t('azerbaijan'),
        this.$t('bahamas'),
        this.$t('bahrain'),
        this.$t('bangladesh'),
        this.$t('barbados'),
        this.$t('belarus'),
        this.$t('belgium'),
        this.$t('belize'),
        this.$t('benin'),
        this.$t('bermuda'),
        this.$t('bhutan'),
        this.$t('bolivia-plurinational-state-of'),
        this.$t('bonaire-sint-eustatius-and-saba'),
        this.$t('bosnia-and-herzegovina'),
        this.$t('botswana'),
        this.$t('bouvet-island'),
        this.$t('brazil'),
        this.$t('british-indian-ocean-territory'),
        this.$t('brunei-darussalam'),
        this.$t('bulgaria'),
        this.$t('burkina-faso'),
        this.$t('burundi'),
        this.$t('cabo-verde'),
        this.$t('cambodia'),
        this.$t('cameroon'),
        this.$t('canada'),
        this.$t('cayman-islands'),
        this.$t('central-african-republic'),
        this.$t('chad'),
        this.$t('chile'),
        this.$t('china'),
        this.$t('christmas-island'),
        this.$t('cocos-keeling-islands'),
        this.$t('colombia'),
        this.$t('comoros'),
        this.$t('congo'),
        this.$t('congo-democratic-republic-of-the'),
        this.$t('cook-islands'),
        this.$t('costa-rica'),
        this.$t('cote-divoire'),
        this.$t('croatia'),
        this.$t('cuba'),
        this.$t('curacao'),
        this.$t('cyprus'),
        this.$t('czech-republic'),
        this.$t('denmark'),
        this.$t('djibouti'),
        this.$t('dominica'),
        this.$t('dominican-republic'),
        this.$t('ecuador'),
        this.$t('egypt'),
        this.$t('el-salvador'),
        this.$t('equatorial-guinea'),
        this.$t('eritrea'),
        this.$t('estonia'),
        this.$t('ethiopia'),
        this.$t('falkland-islands-malvinas'),
        this.$t('faroe-islands'),
        this.$t('fiji'),
        this.$t('finland'),
        this.$t('france'),
        this.$t('french-guiana'),
        this.$t('french-polynesia'),
        this.$t('french-southern-territories'),
        this.$t('gabon'),
        this.$t('gambia'),
        this.$t('georgia'),
        this.$t('germany'),
        this.$t('ghana'),
        this.$t('gibraltar'),
        this.$t('greece'),
        this.$t('greenland'),
        this.$t('grenada'),
        this.$t('guadeloupe'),
        this.$t('guam'),
        this.$t('guatemala'),
        this.$t('guernsey'),
        this.$t('guinea'),
        "Guinea-Bissau",
        this.$t('guyana'),
        this.$t('haiti'),
        this.$t('heard-island-and-mcdonald-islands'),
        this.$t('holy-see'),
        this.$t('honduras'),
        this.$t('hong-kong'),
        this.$t('hungary'),
        this.$t('iceland'),
        this.$t('india'),
        this.$t('indonesia'),
        this.$t('iran-islamic-republic-of'),
        this.$t('iraq'),
        this.$t('ireland'),
        this.$t('isle-of-man'),
        this.$t('israel'),
        this.$t('italy'),
        this.$t('jamaica'),
        this.$t('japan'),
        this.$t('jersey'),
        this.$t('jordan'),
        this.$t('kazakhstan'),
        this.$t('kenya'),
        this.$t('kiribati'),
        this.$t('korea-democratic-peoples-republic-of'),
        this.$t('korea-republic-of'),
        this.$t('kuwait'),
        this.$t('kyrgyzstan'),
        this.$t('lao-peoples-democratic-republic'),
        this.$t('latvia'),
        this.$t('lebanon'),
        this.$t('lesotho'),
        this.$t('liberia'),
        this.$t('libya'),
        this.$t('liechtenstein'),
        this.$t('lithuania'),
        this.$t('luxembourg'),
        this.$t('macao'),
        this.$t('macedonia-the-former-yugoslav-republic-of'),
        this.$t('madagascar'),
        this.$t('malawi'),
        this.$t('malaysia'),
        this.$t('maldives'),
        this.$t('mali'),
        this.$t('malta'),
        this.$t('marshall-islands'),
        this.$t('martinique'),
        this.$t('mauritania'),
        this.$t('mauritius'),
        this.$t('mayotte'),
        this.$t('mexico'),
        this.$t('micronesia-federated-states-of'),
        this.$t('moldova-republic-of'),
        this.$t('monaco'),
        this.$t('mongolia'),
        this.$t('montenegro'),
        this.$t('montserrat'),
        this.$t('morocco'),
        this.$t('mozambique'),
        this.$t('myanmar'),
        this.$t('namibia'),
        this.$t('nauru'),
        this.$t('nepal'),
        this.$t('netherlands'),
        this.$t('new-caledonia'),
        this.$t('new-zealand'),
        this.$t('nicaragua'),
        this.$t('niger'),
        this.$t('nigeria'),
        this.$t('niue'),
        this.$t('norfolk-island'),
        this.$t('northern-mariana-islands'),
        this.$t('norway'),
        this.$t('oman'),
        this.$t('pakistan'),
        this.$t('palau'),
        this.$t('palestine-state-of'),
        this.$t('panama'),
        this.$t('papua-new-guinea'),
        this.$t('paraguay'),
        this.$t('peru'),
        this.$t('philippines'),
        this.$t('pitcairn'),
        this.$t('poland'),
        this.$t('portugal'),
        this.$t('puerto-rico'),
        this.$t('qatar'),
        this.$t('reunion'),
        this.$t('romania'),
        this.$t('russian-federation'),
        this.$t('rwanda'),
        this.$t('saint-barthelemy'),
        this.$t('saint-helena-ascension-and-tristan-da-cunha'),
        this.$t('saint-kitts-and-nevis'),
        this.$t('saint-lucia'),
        this.$t('saint-martin-french-part'),
        this.$t('saint-pierre-and-miquelon'),
        this.$t('saint-vincent-and-the-grenadines'),
        this.$t('samoa'),
        this.$t('san-marino'),
        this.$t('sao-tome-and-principe'),
        this.$t('saudi-arabia'),
        this.$t('senegal'),
        this.$t('serbia'),
        this.$t('seychelles'),
        this.$t('sierra-leone'),
        this.$t('singapore'),
        this.$t('sint-maarten-dutch-part'),
        this.$t('slovakia'),
        this.$t('slovenia'),
        this.$t('solomon-islands'),
        this.$t('somalia'),
        this.$t('south-africa'),
        this.$t('south-georgia-and-the-south-sandwich-islands'),
        this.$t('south-sudan'),
        this.$t('spain'),
        this.$t('sri-lanka'),
        this.$t('sudan'),
        this.$t('suriname'),
        this.$t('svalbard-and-jan-mayen'),
        this.$t('swaziland'),
        this.$t('sweden'),
        this.$t('switzerland'),
        this.$t('syrian-arab-republic'),
        this.$t('taiwan-province-of-china-a'),
        this.$t('tajikistan'),
        this.$t('tanzania-united-republic-of'),
        this.$t('thailand'),
        "Timor-Leste",
        this.$t('togo'),
        this.$t('tokelau'),
        this.$t('tonga'),
        this.$t('trinidad-and-tobago'),
        this.$t('tunisia'),
        this.$t('turkey'),
        this.$t('turkmenistan'),
        this.$t('turks-and-caicos-islands'),
        this.$t('tuvalu'),
        this.$t('uganda'),
        this.$t('ukraine'),
        this.$t('united-arab-emirates'),
        this.$t('united-kingdom-of-great-britain-and-northern-ireland'),
        this.$t('united-states-of-america'),
        this.$t('united-states-minor-outlying-islands'),
        this.$t('uruguay'),
        this.$t('uzbekistan'),
        this.$t('vanuatu'),
        this.$t('venezuela-bolivarian-republic-of'),
        this.$t('viet-nam'),
        this.$t('virgin-islands-british'),
        this.$t('virgin-islands-u-s'),
        this.$t('wallis-and-futuna'),
        this.$t('western-sahara'),
        this.$t('yemen'),
        this.$t('zambia'),
        this.$t('zimbabwe'),
      ],
      password: "",
      fieldRequired: [(v) => !!v || this.$t('field-is-required')],
      emailRules: [
        (v) => !!v || this.$t('e-mail-is-required'),
        (v) => /.+@.+/.test(v) || this.$t('e-mail-must-be-valid'),
      ],
      snackbar: false,
      snack_text: null,
      snack_color: "red",
      date_picker: false,
      totalOrder: 0,
    };
  },
  mounted() {
    this.setUser();
  },
  methods: {
    orderedItem() {
      return this.priceItem.map((value) => {
        if (value.ordered == null) {
          value.ordered = value.minimal;
        }
        return value;
      });
    },
    submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        var bookingdata = {
          activity_id: this.post.id,
          date: this.date,
          adults: this.adults,
          kids: this.kids,
          first_name: this.title + " " + this.first_name,
          last_name: this.last_name,
          email: this.email,
          phone_number: this.phone,
          country: this.country,
          special_request: this.special_request,
          pickup_location: this.pickup,
          payment_method: this.paymentMethod,
          order_data: this.orderedItem(),
          total: this.totalOrder,
        };
        this.$emit("bookingData", bookingdata);
      } else {
        this.snackbar = true;
        this.snack_color = "blue";
        this.snack_text = this.$t('please-fill-in-all-data');
      }
    },
    total(value) {
      this.totalOrder = value;
    },
    cancel() {
      this.$refs.form.reset();
      this.$emit("bookingCancel");
    },
    success(response) {
      this.$emit("bookingSuccess", response);
    },
    loginSuccess() {
      this.loginDialog = false;
      this.setUser();
    },
    setUser() {
      if (this.$store.getters.isAuthenticated) {
        this.user = true;
        this.email = this.$store.getters["getProfile"].email;
        var username = this.$store.getters["getProfile"].name.split(" ");
        this.first_name = username.slice(0, -1).join(" ");
        this.last_name = username.slice(-1).join(" ");
      }
    },
  },
  computed: {
    today: function () {
      var today = new Date();
      var dd = today.getDate();
      var mm = today.getMonth() + 1;
      var yyyy = today.getFullYear();

      if (dd < 10) {
        dd = "0" + dd;
      }

      if (mm < 10) {
        mm = "0" + mm;
      }

      return yyyy + "-" + mm + "-" + dd;
    },
  },
};
</script>

<style scoped>
.top-box {
  background-color: #eee;
  height: 100%;
  padding: 4px;
  font-size: 12px;
}

.n-orange {
  background-color: #ff6100;
  align-items: center;
  border-radius: 50%;
  display: inline-flex;
  font-size: 12px;
  justify-content: center;
  height: 24px;
  margin-right: 8px;
  min-width: 24px;
  width: 24px;
}
.n-gray {
  background-color: #aaa;
  align-items: center;
  border-radius: 50%;
  display: inline-flex;
  font-size: 12px;
  justify-content: center;
  height: 24px;
  margin-right: 8px;
  min-width: 24px;
  width: 24px;
}

.box {
  background-color: #e6e4e5;
  padding: 9px;
  margin: 4px 0;
  font-size: 12px;
}

.box .q {
  color: black;
}

.box .a {
  color: gray;
}

.red-box {
  background-color: #f6d0a3;
  padding: 9px;
  margin: 12px 0;
  font-size: 12px;
}

.text-right {
  text-align: right;
  font-size: 12px;
  color: #888;
  vertical-align: top;
}

.checkout {
  font-size: 12px;
  margin-bottom: 48px;
  text-align: center;
}

a {
  color: green;
}

table {
  border-collapse: collapse;
}
tr {
  border: solid;
  border-color: #37474f;
  border-width: 1px 0;
}

tr:first-child {
  border-top: none;
}
tr:last-child {
  border-bottom: none;
}

.post-name {
  margin-top: 12px;
  font-size: 16px;
  font-weight: bold;
}
.carousel-img {
  width: 100%;
  height: 240px;
  align-content: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 12px;
}

.img {
  width: 100%;
  height: 240px;
  object-fit: cover;
}

@media only screen and (min-width: 768px) {
  .carousel-img {
    width: 100%;
    height: 440px;
    align-content: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .img {
    width: 100%;
    height: 440px;
    object-fit: cover;
  }
}

@media only screen and (min-width: 1024px) {
  .carousel-img {
    width: 100%;
    height: 340px;
    align-content: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .img {
    width: 100%;
    height: 340px;
    object-fit: cover;
  }
}

@media only screen and (min-width: 2560px) {
  .carousel-img {
    width: 100%;
    height: 960px;
    align-content: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .img {
    width: 100%;
    height: 960px;
    object-fit: cover;
  }
}
.sticky {
  position: fixed;
  bottom: 15px;
  width: 100%;
  background-color: white;
  z-index: 100;
}
.img--hero {
  max-width: 100%;
  height: auto;
}

.title_bg {
  display: block;
  margin-left: -16px;
  margin-right: -16px;
  padding: 8px;
  margin-top: 8px;
  background-color: #ddd;
  border-width: 0 0 0 8px;
  border-style: solid;
  border-color: #ff6100;
  font-size: 16px;
  font-weight: bold;
}

.agree {
  text-align: center;
  font-size: 10px;
}
.cofidence--title {
  color: green;
  font-size: 18px;
  margin-top: 18px;
  margin-bottom: 4px;
}

.cofidence {
  background-color: #e3fcf6;
  margin-left: -16px;
  margin-right: -16px;
  padding: 12px;
}

.cofidence ul {
  list-style-image: url("~@/assets/images/check.svg");
}

.bottom--booking {
  margin: -16px;
  border-top-width: 2px;
  border-top-style: solid;
  border-color: #37474f;
}
.bottom--booking span,
small {
  padding-left: 16px;
}
.btn--button {
  height: 100%;
  width: 100%;
  font-size: 18px;
  display: block;
  background-color: #37474f;
  color: white;
}
</style>
