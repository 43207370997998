<template>
  <v-card>
    <v-card-text>
      <table>
        <tr>
          <td class="deep-orange--text text-darken-1">{{ $t('vehicle-type') }}</td>
          <td>{{ post.vehicle_type }}</td>
        </tr>
        <tr>
          <td class="deep-orange--text text-darken-1">MANUFACTURE</td>
          <td>{{ post.manufacture }}</td>
        </tr>
        <tr>
          <td class="deep-orange--text text-darken-1">{{ $t('vehicle-name') }}</td>
          <td>{{ $t('post-vehicle_name-person', [post.vehicle_name]) }}</td>
        </tr>
        <tr>
          <td class="deep-orange--text text-darken-1">{{ $t('passanger') }}</td>
          <td>{{ post.passanger }}</td>
        </tr>
        <tr>
          <td class="deep-orange--text text-darken-1">{{ $t('transmission') }}</td>
          <td>{{ post.transmision }}</td>
        </tr>
        <tr>
          <td class="deep-orange--text text-darken-1">{{ $t('baggage') }}</td>
          <td>{{ post.baggage }}</td>
        </tr>
        <tr>
          <td class="deep-orange--text text-darken-1">{{ $t('price'.toUpperCase) }}</td>
          <td>
            {{ $root.$i18n.locale == "id" ? "IDR " : "USD " }} {{ post.price | fm }}
          </td>
        </tr>
      </table>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  props: ["post"],
};
</script>
<style>
table {
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  vertical-align: text-top;
  text-align: left;
  padding: 2px;
  width: 50%;
}
</style>
