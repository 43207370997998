import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import HomeView from '../components/home/HomeView'
import ActivitiesView from '../components/activity/ActivitiesView'
import ActivityView from '../components/activity/ActivityView'
import DestinationsView from '../components/destination/DestinationsView'
import DestinationView from '../components/destination/DestinationView'
import TransportsView from '../components/transport/TransportsView'
import TransportView from '../components/transport/TransportView'
import Tips from '../components/tips/Tips'
import TipDetail from '../components/tips/TipDetail'
import HelpAndFaqView from '../components/help/HelpAndFaqView'
import AboutUsView from '../components/about/AboutUsView'
import Profile from '../components/profile/Profile'
import Login from '../components/auth/Login'
import Register from '../components/auth/Register'
import CancelationPolicy from '../components/others/CancelationPolicy'
import TermPayment from '../components/others/TermPayment'
import HowToBook from '../components/others/HowToBook'
import ActivityResults from '../components/activity/ActivityResults'
import Event from '../components/event/Event'
import EventDetail from '../components/event/EventDetail'
import SpecialOffer from '../components/specialoffer/SpecialOffer'
import SpecialOfferDetail from '../components/specialoffer/SpecialOfferDetail'
import GalleryPhotos from '../components/home/gallery/GalleryPhotos'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch((err) => {
    if (VueRouter.isNavigationFailure(err)) {
      // resolve err
      return err
    }
    // rethrow error
    return Promise.reject(err)
  })
}

Vue.use(VueRouter)

// function loadView (folder, view) {
//   return () => import(/* webpackChunkName: "view-[request]" */ `../components/${folder}/${view}.vue`)
// }

const routes = [
  {
    path: '/',
    name: 'home',
    // component: loadView('home', 'HomeView')
    component: HomeView
  },
  {
    path: '/activity',
    name: 'activities',
    props: true,
    // component: loadView('activity', 'ActivitiesView')
    component: ActivitiesView
  },
  {
    path: '/results',
    name: 'results',
    props: true,
    // component: loadView('activity', 'ActivitiesView')
    component: ActivityResults
  },
  {
    path: '/activity/:type/:slug',
    name: 'activity',
    // component: loadView('activity', 'ActivityView')
    component: ActivityView
  },
  {
    path: '/destination',
    name: 'destinations',
    // component: loadView('destination', 'DestinationsView')
    component: DestinationsView
  },
  {
    path: '/destination/:regency/:slug',
    name: 'destination',
    // component: loadView('destination', 'DestinationView')
    component: DestinationView
  },
  {
    path: '/transport',
    name: 'transports',
    // component: loadView('transport', 'TransportsView')
    component: TransportsView
  },
  {
    path: '/transport/:id',
    name: 'transport',
    // component: loadView('transport', 'TransportView')
    component: TransportView
  },
  {
    path: '/tips',
    name: 'tips',
    // component: loadView('tips', 'Tips')
    component: Tips
  },
  {
    path: '/tips/:slug',
    name: 'tip',
    // component: loadView('tips', 'TipDetail')
    component: TipDetail
  },
  {
    path: '/event',
    name: 'events',
    // component: loadView('tips', 'Tips')
    component: Event
  },
  {
    path: '/event/:slug',
    name: 'event',
    // component: loadView('tips', 'TipDetail')
    component: EventDetail
  },
  {
    path: '/specialoffer',
    name: 'specialoffers',
    // component: loadView('tips', 'Tips')
    component: SpecialOffer
  },
  {
    path: '/specialoffer/:slug',
    name: 'specialoffer',
    // component: loadView('tips', 'TipDetail')
    component: SpecialOfferDetail
  },
  {
    path: '/helpandfaq',
    name: 'helpandfaq',
    // component: loadView('help', 'HelpAndFaqView')
    component: HelpAndFaqView
  },
  {
    path: '/aboutus',
    name: 'aboutus',
    // component: loadView('about', 'AboutUsView')
    component: AboutUsView
  },
  {
    path: '/profile',
    name: 'profile',
    // component: loadView('profile', 'Profile'),
    component: Profile,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'login',
    // component: loadView('auth', 'Login')
    component: Login
  },
  {
    path: '/register',
    name: 'register',
    // component: loadView('auth', 'Register')
    component: Register
  },
  {
    path: '/cancelation',
    name: 'cancelation',
    // component: loadView('others', 'CancelationPolicy')
    component: CancelationPolicy
  },
  {
    path: '/term',
    name: 'term',
    // component: loadView('others', 'TermPayment')
    component: TermPayment
  },
  {
    path: '/howtobook',
    name: 'howtobook',
    // component: loadView('others', 'HowToBook')
    component: HowToBook
  },
  {
    path: '/gallery',
    name: 'gallery',
    // component: loadView('others', 'HowToBook')
    component: GalleryPhotos
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0)
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters.isAuthenticated) {
      next({
        name: 'login',
        params: { nextUrl: to.name }
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
