<template>
  <v-container fluid grid-list-md pa-0>
    <v-layout row wrap>
      <v-flex text-center pv-2 xs12 sm8 offset-sm2 md4 offset-md4>
        <v-btn
          :loading="facebookLoading"
          block
          color="blue darken-3"
          class="white--text"
          @click="loginFacebook"
          >{{ $t('facebook') }}</v-btn
        >
      </v-flex>
      <v-flex text-center pv-2 xs12 sm8 offset-sm2 md4 offset-md4>
        <v-btn
          :loading="googleLoading"
          block
          color="red darken-1"
          class="white--text"
          @click="loginGoogle"
          >{{ $t('google') }}</v-btn
        >
      </v-flex>
    </v-layout>
    <v-snackbar v-model="snackbar" :color="'red'" multi-line>
      {{ message }}
      <v-btn dark text @click="snackbar = false"> {{ $t('close') }} </v-btn>
    </v-snackbar>
  </v-container>
</template>
<script>
/* eslint-disable */
import axios from "axios";
import dotenv from "dotenv";
dotenv.config();

const baseUrl = process.env.VUE_APP_BASE_URL;
export default {
  data() {
    return {
      message: null,
      snackbar: false,
      facebookLoading: false,
      googleLoading: false,
    };
  },
  methods: {
    loginFacebook() {
      this.facebookLoading = true;
      window.FB.login((response) => {
        if (response.status === "connected") {
          axios
            .get(baseUrl+"/sanctum/csrf-cookie")
            .then((res) => {
              axios
                .post(baseUrl+`/api/auth/facebook`, {
                  code: response.authResponse.accessToken,
                })
                .then((response) => {
                  this.facebookLoading = false;
                  this.$emit("success", response);
                })
                .catch((e) => {
                  this.errorMessage();
                  this.facebookLoading = false;
                });
            });
        } else {
          // The person is not logged into this app or we are unable to tell.
          this.errorMessage();
          this.facebookLoading = false;
        }
      });
    },
    loginGoogle() {
      this.googleLoading = true;
      window.gapi.auth2
        .getAuthInstance()
        .signIn()
        .then((response) => {
          axios
            .get(baseUrl+"/sanctum/csrf-cookie")
            .then((res) => {
              axios
                .post(baseUrl+`/api/auth/google`, {
                  code: response.Zi.access_token,
                })
                .then((response) => {
                  this.googleLoading = false;
                  this.$emit("success", response);
                })
                .catch((e) => {
                  this.googleLoading = false;
                  this.errorMessage();
                });
            });
        })
        .catch((e) => {
          this.errorMessage();
          this.googleLoading = false;
        });
    },
    errorMessage() {
      this.snackbar = true;
      this.message = this.$t('something-went-wrong-please-try-again');
    },
  },
};
</script>
<style>
</style>