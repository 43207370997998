<template>
  <div>
    <div v-for="(pack, index) in packages" :key="index" class="price">
      <v-layout row wrap class="header">
        <v-flex xs12>
          <span>{{ pack.name }}</span>
        </v-flex>
      </v-layout>
      <v-layout
        row
        wrap
        px-3
        py-2
        v-show="active !== index"
        style="font-size: 16px; background: #f8f7ff; margin: 0.5px 0 0.5px 0"
      >
        <v-flex xs8>
          <span>{{ pack.products[0].name }}</span
          ><br />
        </v-flex>
        <v-flex xs4 class="text-xs-right">
          <span>
            {{ $root.$i18n.locale == "id" ? "IDR " : "USD " }}
            {{ $root.$i18n.locale == "id" ? pack.products[0].price_idr : pack.products[0].price_usd | fm }}</span
          >
        </v-flex>
      </v-layout>
      <div v-show="active === index" style="background: #f8f7ff">
        <div
          class="product_item"
          row
          wrap
          v-for="(product, index) in pack.products"
          :key="index"
        >
          <v-layout>
            <v-flex xs8 pl-4>
              <span>{{ product.name }}</span
              ><br />
            </v-flex>
            <v-flex xs4 pr-4 class="text-xs-right">
              <span>
                {{ $root.$i18n.locale == "id" ? "IDR " : "USD " }}
                {{ $root.$i18n.locale == "id" ? product.price_idr : product.price_usd | fm }}</span
              >
            </v-flex>
          </v-layout>
        </div>
        <v-layout row wrap pa-3>
          <v-flex xs12>
            <span style="color: #6c7482" v-html="pack.detail"></span>
          </v-flex>
        </v-layout>
      </div>
      <v-layout row wrap v-show="active !== index" pa-0>
        <v-flex xs5 ml-2>
          <v-btn
            depressed
            small
            rounded
            color="deep-orange white--text text-capitalize"
            @click="$emit('booking-now')"
            >{{ $t('book-now') }}</v-btn
          >
        </v-flex>
        <v-flex xs6>
          <div class="grey--text" @click="setActive(index)">{{ $t('details') }}</div>
        </v-flex>
      </v-layout>
      <v-layout row wrap v-show="active === index" pa-0>
        <v-flex xs5 ml-2>
          <v-btn
            small
            rounded
            color="deep-orange white--text text-capitalize"
            @click="$emit('booking-now')"
            >{{ $t('book-now') }}</v-btn
          >
        </v-flex>
        <v-flex xs6>
          <div class="grey--text" @click="setActive(index)">{{ $t('hide') }}</div>
        </v-flex>
      </v-layout>
    </div>
  </div>
</template>
<script>
export default {
  props: ["packages"],
  data() {
    return {
      active: null,
    };
  },
  methods: {
    setActive(index) {
      this.active === index ? (this.active = null) : (this.active = index);
    },
  },
};
</script>
<style scoped>
.header {
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  background-color: #37474f;
  color: white;
  padding: 12px;
  font-size: 16px;
  font-weight: bold;
  border-width: 2px;
  border-style: solid;
}

.grey--text {
  text-align: right;
  padding: 12px 0 0 0;
}
.price {
  border-color: #d7d7d7;
  border-width: 2px;
  border-style: solid;
  margin-bottom: 8px;
  background-color: white;
  border-radius: 8px;
}
.pack {
  font-size: 16px;
  font-weight: bold;
}

.orange--box {
  border-width: 1px;
  padding: 5px 12px;
  border-style: solid;
  border-color: #ff6100;
  border-radius: 5px;
  text-align: right;
}

.product_item {
  padding: 12px 0;
  border-style: solid;
  border-color: white;
  font-size: 16px;
  border-width: 0 0 3px 0;
  color: #37474f;
}
</style>


