<template>
  <div>
    <div v-for="(item, index) in items" :key="index" style="margin-top: 12px">
      <v-layout row wrap>
        <v-flex xs8>
          <span class="body-2">{{ item.name }}</span
          ><br />
          <span class="body-1">
            {{ $root.$i18n.locale == "id" ? "IDR " : "USD " }}
            {{ $root.$i18n.locale == "id" ? item.price_idr : item.price_usd | fm }}</span
          >
        </v-flex>
        <v-flex xs4>
          <v-layout row wrap>
            <v-flex xs12>
              <integer-plusminus
                :min="item.minimal"
                :max="100"
                :step="1"
                v-model="items[index].ordered"
              >
                {{ items[index].ordered || item.minimal }}
                <template slot="decrement" @click="calculateTotal">
                  +
                </template>

                <template slot="increment" @click="calculateTotal">
                  -
                </template>
              </integer-plusminus>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
      <v-divider
        v-show="index !== items.length - 1"
        style="margin-top: 10px; margin-bottom: 10px"
      ></v-divider>
    </div>
  </div>
</template>
<script>
export default {
  props: ["items"],
  data() {
    return {
      products: [],
      text: "center",
      icon: "justify",
      toggle_none: null,
      // currentItems: null
    };
  },
  // mounted () {
  //   this.currentItems = this.items.map(value =>{
  //     value.ordered = value.minimal
  //     return value
  //   })
  // },
  computed: {
    calculateTotal() {
      var total = 0;
      this.items.forEach((item, index) => {
        var order = isNaN(item.ordered) ? item.minimal : item.ordered;
        var nominal = this.$root.$i18n.locale == "id" ? item.price_idr : item.price_usd;
        var subTotal = nominal * order;
        total += subTotal;
      });
      this.$emit("total", total);
      return total;
    },
  },
};
</script>
<style scoped>
.v-center {
  display: flex;
  vertical-align: middle;
}
</style>
