<template>
  <v-card
    class="black--text"
    :to="{ name: 'tip', params: { slug: post.slug } }"
  >
    <v-img
      :src="post.images[0].thumbnail"
      :alt="post.images[0].thumbnail"
      height="150px"
      width="100%"
    >
    </v-img>
    <v-card-title>
      <div>
        <span class="text-body-1">{{ post.title }}</span>
      </div>
    </v-card-title>
  </v-card>
</template>
<script>
export default {
  props: ["post"],
};
</script>
<style>
.rating {
  background-color: #37464d;
  color: white;
  padding: 4px;
  text-align: center;
  border-radius: 4px;
  width: auto;
  max-width: 40px;
}
.carousel-img {
  width: 100%;
  height: 240px;
  align-content: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (min-width: 768px) {
  .carousel-img {
    width: 100%;
    height: 480px;
    align-content: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (min-width: 2560px) {
  .carousel-img {
    width: 100%;
    height: 960px;
    align-content: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}
</style>
