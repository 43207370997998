<template>
  <div>
    <div class="text-yellow">
      {{ $t("the-most-frequently-asked-question-about-bali-trip-center") }}
    </div>
    <v-flex sm12 my-2>
      <v-expansion-panels>
        <v-expansion-panel
          v-for="(item, i) in faqs"
          :key="i"
          popout
          :value="item.name === 'General Questions'"
        >
          <v-expansion-panel-header>
            {{ item.name }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div
              class="box-green"
              v-for="(item, index) in item.items"
              :key="index"
            >
              <v-layout>
                <v-flex xs1> Q: </v-flex>
                <v-flex xs11>
                  {{ item.q }}
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex xs1> A: </v-flex>
                <v-flex xs11>
                  {{ item.a }}
                </v-flex>
              </v-layout>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-flex>
    <div class="box-green2" style="margin-top: 10px">
      {{ more }}
    </div>
  </div>
</template>
<script>
/* eslint-disable */
export default {
  data() {
    return {
      faqs: [
        {
          name: this.$t("general-questions"),
          items: [
            {
              q: this.$t(
                "after-booking-how-long-can-i-wait-for-the-confirmation"
              ),
              a: this.$t(
                "the-confirmation-depends"
              ),
            },
            {
              q: this.$t("what-currency-does-bali-trip-center-accept"),
              a: this.$t("we-accept-usd-if-you"),
            },
          ],
        },
        {
          name: this.$t("booking-process"),
          items: [
            {
              q: this.$t("how-can-i-make-a-reservation"),
              a: this.$t(
                "all-you-have-to-is"
              ),
            },
            {
              q: this.$t(
                "after-booking-how-long-can-i-wait-for-the-confirmation"
              ),
              a: this.$t(
                "the-confirmation-depends"
              ),
            },
            {
              q: this.$t("how-can-i-know-if-my-reservation-is-confirmed"),
              a: this.$t(
                "you-will-receive-the"
              ),
            },
            {
              q: this.$t("what-will-i-get-after-i-make-a-reservation"),
              a: this.$t("youll-receive-an-email"),
            },
            {
              q: this.$t(
                "what-should-i-do-if"
              ),
              a: this.$t(
                "please-check-the-spam"
              ),
            },
            {
              q: this.$t("how-can-i-get-to-the-activity-site-correctly"),
              a: this.$t(
                "in-the-activity-detail"
              ),
            },
          ],
        },
        {
          name: this.$t("payment-process"),
          items: [
            {
              q: this.$t("what-currency-does-bali-trip-center-accept"),
              a: this.$t("we-accept-usd-if-you"),
            },
            {
              q: this.$t("can-i-know-the-detail-of-the-payment"),
              a: this.$t("yes-you-can-you-should"),
            },
            {
              q: this.$t("is-booking-charged"),
              a: this.$t("if-you-pay-cash-theres"),
            },
            {
              q: this.$t("what-is-the-payment-method"),
              a: this.$t("you-can-pay-cash"),
            },
            {
              q: this.$t("what-if-i-pay-cash"),
              a: this.$t("you-are-welcome"),
            },
            {
              q: this.$t("is-my-payment-safe"),
              a: this.$t("our-online-payment"),
            },
            {
              q: this.$t("is-there-any-discounts"),
              a: this.$t("we-give-our-clients"),
            },
          ],
        },
        {
          name: this.$t("refunds-process"),
          items: [
            {
              q: this.$t("if-i-cancel-my-booking-can-i-get-a-refund"),
              a: this.$t("please-see-the-detail"),
            },
            {
              q: this.$t(
                "if-i-have-canceled-my-booking-and-i-havent-received-my-refund-what-should-i-do"
              ),
              a: this.$t("please-let-us-know-via"),
            },
            {
              q: this.$t(
                "is-it-possible-for-me-to-change-my-reservation-after-i-finish-paying"
              ),
              a: this.$t("you-may-contact-us"),
            },
          ],
        },
        {
          name: this.$t("account-settings"),
          items: [
            {
              q: this.$t("how-can-i-create-an-account"),
              a: this.$t(
                "you-can-sign-up-with-your-personal-email-google-account-or-via-your-facebook-login"
              ),
            },
            {
              q: this.$t("is-it-possible-to-reset-my-password"),
              a: this.$t(
                "yes-just-send-us-an-email-via-support-balitripcenter-com"
              ),
            },
            {
              q: this.$t("if-i-forget-my-password-what-should-i-do"),
              a: this.$t(
                "you-can-send-us-an-email-to-support-balitripcenter-com"
              ),
            },
            {
              q: this.$t("is-it-possible-to-delete-my-account"),
              a: this.$t(
                "yes-you-should-send-an-email-to-support-balitripcenter-com"
              ),
            },
          ],
        },
      ],
      more: this.$t(
        "for-futher-questions-please-contact-our-support-or-email-support-balitripcenter-com"
      ),
    };
  },
};
</script>

<style scoped>
.text-yellow {
  color: #ff6100;
  font-size: 16px;
}
.box-green {
  color: black;
  display: block;
  padding: 8px;
  font-size: 12px;
  background-color: #f3fbc0;
}
.box-green2 {
  color: black;
  display: block;
  padding: 8px;
  font-size: 12px;
  background-color: #ecfc65;
}
</style>

