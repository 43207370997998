<template>
  <div class="body">
    <div class="header-contanier">
      <agile class="header-image" :autoplay="true" :navButtons="false" :pauseOnHover="true">
        <div class="slide clip">
          <img src="@/assets/images/banner/b1.jpg" alt />
        </div>
        <div class="slide clip">
          <img src="@/assets/images/banner/b2.jpg" alt />
        </div>
        <div class="slide clip">
          <img src="@/assets/images/banner/b3.jpg" alt />
        </div>
        <div class="slide clip">
          <img src="@/assets/images/banner/b4.jpg" alt />
        </div>
        <div class="slide clip">
          <img src="@/assets/images/banner/b5.jpg" alt />
        </div>
      </agile>
      <div class="search-main">
        <div class="search-container">
          <input
            type="text"
            :placeholder="$t('find-activities-tours-4g-modem-and-private-transport')"
            v-model="queryInput"
          />
          <v-btn icon color="black" @click="toSearch" v-on:keyup.enter="toSearch">
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
        </div>
      </div>
    </div>
    <!-- <div class="category-title">{{ $t('explore-activties-by-category') }}</div> -->
    <div style="background-color: #E7F2F8">
    <div class="pa-2 scrolling-wrapper">
      <div v-for="item in categories" :key="item.name">
        <div @click="selected(item.id)">
          <div v-if="active != item.id">
            <div class="card">
              <img class="type-img" :src="item.images[0].thumbnail" :alt="item.name" />
              <div>{{ item.name }}</div>
            </div>
          </div>
          <div v-else>
            <div class="card active">
              <img class="type-img" :src="item.images[0].thumbnail" :alt="item.name" />
              <div>{{ item.name }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-container class="pa-4" v-if="category" v-show="!loading" fluid grid-list-md>
      <v-layout row wrap>
        <v-flex xs7>
          <span style="color: #FF6100;font-size: 20px;">{{ category.name }}</span>
          <read-more
          class="caption"
            style="color: gray"
            :more-str="$t('read-more')"
            :less-str="$t('read-less')"
            :text="category.des"
            :max-chars="120"
          ></read-more>
        </v-flex>
        <v-flex xs5>
          <img src="@/assets/images/activity_hero/icon_acitvity.png" class="float-right" width="150" height="150" />
        </v-flex>
      </v-layout>
    </v-container>
    </div>
    <div v-if="posts && posts.length">
      <v-container pa-2 fluid grid-list-md>
        <v-layout row wrap>
          <v-flex pa-3 v-for="(post, i) of posts" :key="i" xs12 sm6 xl4>
            <activity-card-new :post="post"></activity-card-new>
          </v-flex>
        </v-layout>
      </v-container>
      <div class="text-center" v-if="!loading && currentPage != null">
        <v-btn rounded class="white--text" color="green" @click="getPosts">{{ $t('load-more') }}</v-btn>
      </div>
    </div>
    <v-flex xs12 v-else v-show="!loading" ma-2 text-center>
      <span>{{ $t('this-category-doesnt-have-activity-yet') }}</span>
    </v-flex>
    <loading :loading="loading"></loading>

    <v-flex sm12 pa-2>
    <home-populars></home-populars>
    <br />
    <home-recommendations></home-recommendations>
    <br />
    <home-water-sport></home-water-sport><br />
    <home-tour></home-tour>
    <br />
    <gallery-home></gallery-home>
    <br />
    </v-flex>
    <v-flex xs12 sm6 pa-3>
      <div class="simple-subscription-form">
        <v-form ref="form" v-model="valid" lazy-validation>
          <span class="title">{{ $t('lets-subscribe') }}</span>
          <div class="input-group">
            <input
              class="input"
              type="email"
              v-model="email"
              :placeholder="$t('please-enter-your-email')"
              required
            />
            <button class="button" type="submit" @click="subscribe">{{ $t('subscribe') }}</button>
          </div>
        </v-form>
      </div>
    </v-flex>
    <div style="color: #aaa; padding: 0 12px;">
      {{ $t('by-signing-up-you-agree-to-receive-promotional-emails') }}
      {{ $t('you-can-unsubscribe-at-any-time') }}
    </div>
    <br />

    <div class="box">
      <v-layout>
        <v-flex xs3>
          <img
            src="@/assets/images/cs.png"
            height="auto"
            width="100%"
            alt="$t('contact-us')"
          /><br />
        </v-flex>
        <v-flex xs9>
          <span class="caption"
            >{{ $t('t-did-you-have-trouble-or-questions-feel-free-contact-us', [$t('did-you-have-trouble-or-questions-feel-free-contact-us')]) }}</span
          ><br />
          <a class="email" href="mailto:support@balitripcenter.com">
            <span class="caption">support@balitripcenter.com</span>
          </a>
        </v-flex>
      </v-layout>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import axios from "axios";
import ActivityCardNew from "./ActivityCardNew.vue";
import dotenv from "dotenv";
dotenv.config();

const baseUrl = process.env.VUE_APP_BASE_URL;
const api = baseUrl+`/api/type`;

export default {
    props: ["type"],
    data() {
        return {
            loading: true,
            posts: [],
            errors: [],
            currentPage: api,
            valid: true,
            email: null,
            categories: [
                {
                    id: null,
                    name: this.$t("all-activity"),
                    title: this.$t("all-available-activities-at-our-site"),
                    des: this.$t("bali-is-an-island-or-actually"),
                    created_at: this.$t("2018-07-09-08-28-51"),
                    updated_at: this.$t("2018-07-09-08-28-51"),
                    images: [
                        {
                            thumbnail: baseUrl+"/images/bali-allactivity_xs.png",
                            original: baseUrl+"/images/bali-allactivity.png",
                        },
                    ],
                },
            ],
            category: null,
            category_name: null,
            category_des: null,
            active: null,
            queryInput: null,
        };
    },
    beforeMount() {
        this.getCategories();
    },
    mounted() {
        if (!this.type) {
            this.getPosts();
        }
        else {
            this.selected(this.type);
            this.active = this.type;
        }
    },
    methods: {
        // scroll () {
        //   let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight
        //   if (bottomOfWindow && !this.loading && this.currentPage) {
        //     this.loadMore()
        //   }
        // },
        toSearch() {
            this.$router.push({
                name: "results",
                params: { initialQuery: this.queryInput },
            });
        },
        subscribe() {
            if (this.$refs.form.validate()) {
                http
                    .get(baseUrl+"/sanctum/csrf-cookie")
                    .then((res) => {
                    axios
                        .post(baseUrl+`/api/subscriber`, {
                        email: this.email,
                    })
                        .then((response) => {
                        this.email = null;
                    })
                        .catch((e) => { });
                });
            }
        },
        selected(id) {
            this.posts = [];
            if (id == null) {
                this.currentPage = baseUrl+`/api/type`;
                this.active = null;
            }
            else {
                this.currentPage = baseUrl+`/api/type/` + id;
                this.active = id;
            }
            this.getPosts();
        },
        getCategories() {
            axios
                .get(baseUrl+`/api/types`)
                .then((response) => {
                this.categories = this.categories.concat(response.data.items);
                //console.log("categories " + this.categories);
            })
                .catch((e) => { });
        },
        getPosts() {
            this.loading = true;
            axios
                .get(this.currentPage)
                .then((response) => {
                this.loading = false;
                this.posts = this.posts.concat(response.data.items.data);
                this.category = response.data.category;
                this.currentPage = response.data.items.next_page_url;
            })
                .catch((e) => {
                this.loading = false;
            });
        },
    },
    components: { ActivityCardNew }
};
</script>
<style scoped>
.header-contanier {
  position: relative;
}

.header-image {
  height: 100%;
  width: 100%;
}

.search-main {
  position: absolute;
  bottom: -15px;
  left: 10%;
  right: 10%;
}

.search-container {
  position: relative;
  border-style: solid;
  border-width: 1px;
  border-radius: 15px;
  background-color: #fff;
  border-color: #eee;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.search-container input[type="text"] {
  font-size: 17px;
  padding: 8px;
  width: 100%;
  height: 50px;
  text-indent: 30px;
}
.search-container button {
  position: absolute;
  left: 0;
  padding: 8px;
  font-size: 17px;
  border: none;
  height: 100%;
  color: white;
  background-color:white;
  cursor: pointer;
  border-radius: 15px;
}

.search-container button:hover {
  background: #ccc;
}

.scrolling-wrapper {
  display: flex;
  flex-wrap: nowrap;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;
  overflow-x: scroll;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.scrolling-wrapper .card {
  display: inline-block;
  width: auto;
  min-width: 120px;
  text-align: center;
  background-color: white;
  margin: 4px 8px;
  height: auto;
  min-height: 60px;
  padding: 8px;
  font-weight: bold;
  flex: 0 0 auto;
  font-size: 10px;
  margin-top: 20px;
  border-radius: 12px;
  border-width: 2px;
  border-style: solid;
  border-color: white;
  scroll-snap-align: center;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}
.scrolling-wrapper .card.active {
  border-color: #FF6100;
}

.type-img {
  width: 40px;
  height: 40px;
  object-fit: contain;
  object-position: center;
  margin-bottom: 4px;
}

.clip img {
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: center;
}

.rating {
  background-color: #37464d;
  color: white;
  padding: 4px;
  text-align: center;
  border-radius: 4px;
  width: auto;
  max-width: 40px;
}

.title {
  color: black;
  font-size: 28px;
  font-weight: 400;
}

.input-group {
  border-radius: 4px;
  margin-top: 12px;
  position: relative;
  height: 40px;
  border-color: black;
  border-style: solid;
  border-width: 2px;
  background: white;
  width: 100%;
}

.button {
  border-radius: 0 4px 4px 0;
  background-color: black;
  padding: 8px 12px;
  position: absolute;
  height: 100%;
  right: 0;
  color: white;
}

.input {
  padding: 8px;
  margin: 0px;
  width: 80%;
  height: 100%;
  left: 0;
  position: absolute;
  background-color: white;
}

.category-title {
  padding: 8px;
  font-size: 18px;
  color: #222;
}

.box {
  background-color: #e7f2f8;
  margin: 8px;
  padding: 8px;
  border-radius: 12px;
}

.box img {
  margin-top: 10px;
}

.email,
.email:hover {
  text-decoration: none;
  color: black;
}
</style>