<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dense>
        <v-btn icon @click.native="cancelComment">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-toolbar-title>{{ $t('comment-form') }}</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text>
        <v-form class="mt-4" ref="form" v-model="valid" lazy-validation>
          <span class="captions grey--text">{{ $t('rating') }}</span>
          <v-rating
            v-model="starRating"
            background-color="gray"
            color="yellow accent-4"
            size="18"
          ></v-rating>
          <v-text-field
            required
            v-model="title"
            :rules="rules"
            :label="$t('comment-title')"
          ></v-text-field>
          <v-textarea
            required
            v-model="body"
            :rules="rules"
            :label="$t('comment-body')"
          ></v-textarea>
          <v-btn
            color="blue"
            block
            class="white--text"
            :disabled="!valid"
            :loading="submitLoading"
            @click="postComment"
          >
            {{ $t('submit') }} </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from "axios";
import dotenv from "dotenv";
dotenv.config();

const baseUrl = process.env.VUE_APP_BASE_URL;
export default {
  props: ["dialog", "url"],
  data() {
    return {
      submitLoading: false,
      valid: false,
      title: "",
      body: "",
      rules: [(v) => !!v || this.$t('this-field-is-required')],
      starRating: 4,
    };
  },
  methods: {
    postComment() {
      if (this.$refs.form.validate()) {
        this.submitLoading = true;
        axios
          .get(baseUrl+"/sanctum/csrf-cookie")
          .then((res) => {
            axios
              .post(
                this.url,
                {
                  rating: this.starRating,
                  title: this.title,
                  body: this.body,
                },
                {
                  headers: {
                    Authorization: "Bearer " + this.$store.getters["getToken"],
                  },
                }
              )
              .then((response) => {
                this.submitLoading = false;
                this.$emit("commentSuccess");
                this.resetComment();
              })
              .catch((e) => {
                this.submitLoading = false;
              });
          });
      }
    },
    setRating(rating) {
      this.starRating = rating;
    },
    cancelComment() {
      this.$emit("cancelDialog");
      this.resetComment();
    },
    resetComment() {
      this.title = "";
      this.body = "";
      this.valid = false;
      this.starRating = 4;
    },
  },
};
</script>

