<template>
  <div>
    <v-container fluid grid-list-md v-if="user && !loading">
      <v-layout row wrap mt-4>
        <v-flex xs12 md2 text-center>
          <img class="avatar" :src="user.image" alt="" />
        </v-flex>
        <v-flex xs12 md10>
          <span class="body-2">{{ $t('name') }}</span><br />
          <span class="headline">{{ user.name }}</span
          ><br /><br />
          <span class="body-2">{{ $t('email') }}</span><br />
          <span class="headline">{{ user.email }}</span>
        </v-flex>
        <v-flex xs12 mt-4 md6 offset-md2>
          <v-btn color="red" class="white--text" block @click="logout">
            <!-- <v-icon left dark>face</v-icon> -->
            {{ $t('logout') }} </v-btn>
        </v-flex>
      </v-layout>
    </v-container>
    <!-- <loading :loading="loading"></loading> -->
  </div>
</template>
<script>
// import axios from 'axios'
export default {
  data() {
    return {
      loading: false,
      user: null,
    };
  },
  mounted() {
    this.fatchData();
  },
  methods: {
    fatchData() {
      this.user = this.$store.getters["getProfile"];
      // this.loading = true
      // axios.get(baseUrl+`/api/profile?token=" + this.$store.getters['auth/getToken'])
      // .then(response => {
      //   this.loading = false
      //   this.user = response.data.items
      //   //console.log(response)
      // })
      // .catch(e => {
      //   this.loading = false
      //   if (e.response.status === 400 || e.response.status === 500) {
      //     this.logout()
      //   }
      // })
    },
    logout() {
      if (this.user != null) {
        this.$store.dispatch("removeToken");
        this.$store.dispatch("removeProfile");
        this.$router.push("login");
      }
    },
  },
};
</script>
<style scoped>
.avatar {
  border-radius: 50%;
  width: 120px;
  height: 120px;
  align-content: center;
}
</style>
