<template>
  <a
    :href="'https://m.balitripcenter.com/transport/' + post.id"
    target="_blank"
  >
    <!-- <v-card class="black--text" :to="{ name: 'transport', params: { id: post.id }}"> -->
    <v-card>
      <v-container fluid grid-list-lg pa-2>
        <v-layout row>
          <v-flex xs5>
            <v-img
              :src="post.images[0].thumbnail"
              :alt="post.images[0].thumbnail"
              height="150px"
              width="100%"
            >
            </v-img>
          </v-flex>
          <v-flex xs7>
            <div>
              <span class="title">{{
                post.manufacture + " " + post.vehicle_name
              }}</span
              ><br />
              <v-flex d-inline-flex pa-0>
                <span class="rating">{{ $t('post-rating', [post.rating]) }}</span>
                <v-rating
                  v-model="post.rating"
                  background-color="gray"
                  color="yellow accent-4"
                  dense
                  size="15"
                ></v-rating> </v-flex
              ><br />
              <small class="caption">{{ $t('duration-post-duration', [post.duration]) }}</small
              ><br />
              <small class="caption"
                >{{ $t('post-visitor-visitor-or-post-comments_count-review', [post.visitor, post.comments_count]) }}w</small
              ><br />
              <v-flex xs12 float-right mr-1>
                <span class="title green--text">
                  {{ $root.$i18n.locale == "id" ? "IDR " : "USD " }}
                  {{ post.price | fm }}</span
                ><br />
              </v-flex>
            </div>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card>
  </a>
</template>
<script>
export default {
  props: ["post"],
};
</script>
<style scoped>
.rating {
  background-color: #37464d;
  color: white;
  padding: 4px;
  text-align: center;
  border-radius: 4px;
  width: auto;
  max-width: 40px;
}

a:hover,
a:visited,
a:link,
a:active {
  color: black;
  background-color: transparent;
  text-decoration: none;
}
</style>
