<template>
    <div class="int-pm">
        <div class="int-pm-value">
            <slot>{{ intValue }}</slot>
        </div>
        <div class="int-pm-btn-right" v-on:click="increment()">
            <slot :name="'decrement'">{{'+'}}</slot>
        </div>
        <div class="int-pm-btn-left" v-on:click="decrement()">
            <slot :name="'increment'">{{'-'}}</slot>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'IntegerPlusminus',
    props: {
      value: {
        default: 0,
        type: Number
      },
      min: {
        default: 0,
        type: Number
      },
      max: {
        default: undefined,
        type: Number
      },
      step: {
        default: 1,
        type: Number
      }
    },
    data () {
      return {
        intValue: 0
      }
    },
    computed: {
      canIncrement () {
        return (this.max === undefined || ((this.intValue + this.step) <= this.max))
      },
      canDecrement () {
        return ((this.intValue - this.step) >= this.min)
      }
    },
    methods: {
      getBtnClass (firstBtn) {
        let btnClass = 'int-pm-'
        if ((firstBtn && !this.vertical) || (!firstBtn && this.vertical)) {
          btnClass += 'decrement'
          if (!this.canDecrement) btnClass += ' disabled'
        } else {
          btnClass += 'increment'
          if (!this.canIncrement) btnClass += ' disabled'
        }
        return btnClass
      },
      increment () {
        if (this.canIncrement) {
          this.intValue = this.intValue + this.step
          this.$emit('ipm-increment', this.intValue)
          this.$emit('input', this.intValue)
        }
      },
      decrement () {
        if (this.canDecrement) {
          this.intValue = this.intValue - this.step
          this.$emit('ipm-decrement', this.intValue)
          this.$emit('input', this.intValue)
        }
      }
    },
    watch: {
      value: {
        handler: function (newVal, oldVal) {
          this.intValue = newVal
        }
      }
    },
    created () {
      this.intValue = this.value
      if (this.step < 1) this.step = 1
      if (this.max < this.min) this.max = undefined
    }
  }
</script>

<style scoped>
.int-pm {
  display: table;
  text-align: center;
  position: relative;
}
.int-pm div {
  display: table-cell;
  vertical-align: middle;
}
.int-pm .int-pm-value {
  padding: 0 10px;
  font-size: 20px;
}

.int-pm .int-pm-btn-right {
  border-width: 1px 0 1px 1px;
  border-style: solid;
  border-color:#ff6100;
  cursor: pointer;
  color:#ff6100;
  padding: 5px 12px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.int-pm .int-pm-btn-left{
  border-width: 1px 1px 1px 1px;
  border-style: solid;
  border-color:#ff6100;
  cursor: pointer;
  color:#ff6100;
  padding: 5px 12px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
/* .int-pm .int-pm-btn:hover {
  background-color:  rgb(243, 144, 114);
} */
.int-pm .int-pm-btn.disabled {
  background-color: #eee;
  color: #777;
  cursor: not-allowed;
}
.int-pm .int-pm-btn.disabled:hover {
  background-color: #eee;
}


</style>

