<template>
  <v-card class="" elevation="" max-width="">
    <v-card-text>
      <v-row>
        <v-col cols="3">
          <v-icon class="box" color="orange" size="18">mdi-lock</v-icon>
        </v-col>
        <v-col cols="9">
          <div style="color: black">{{ $t('masuk-akun-sekarang') }}</div>
          <div style="font-size: 10px; color: #aaa">
            {{ $t('nikmati-mudahnya-melakukan-pemesanan-hanya-satu-kall-daftar') }} </div>
          <v-btn to="login" block small elevation="0" class="orange--text"
            >{{ $t('masuk-daftar') }}</v-btn
          >
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {};
</script>

<style scoped>
.box {
  padding: 24px;
  background-color: rgb(240, 240, 240);
  border-radius: 8px;
}
</style>