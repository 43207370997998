<template>
  <v-flex xs12 pa-2>
    <span class="body-2">{{ $t('cancelation-policy') }}</span>
    <ul>
      <li>{{ $t('cancellation-fee-to-50') }}</li>
      <li>{{ $t('no-show-will-be-charge-of-100-of-the-total-payment') }}</li>
    </ul>
  </v-flex>
</template>
<script>
export default {
  
}
</script>
<style scoped>
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

li {
  padding-left: 1em; 
  text-indent: -.7em;
  text-align: justify;
}

li::before {
  content: "•";
  color: rgb(255, 0, 0);
  vertical-align: middle;
  font-size: 20px;
  margin-right: 2px;
}
</style>

