<template>
  <v-container fluid grid-list-md pa-0>
    <v-layout row wrap v-for="(comment, index) in comments" :key="index">
      <v-flex xs3>
        <div v-if="comment.user">
          <img class="avatar" :src="comment.user.image" />
        </div>
        <div v-else>
          <img
            class="avatar"
            src=baseUrl+"/images/profile/default.jpg"
          />
        </div>
      </v-flex>
      <v-flex xs9>
        <div v-if="comment.user">
          <b>{{ comment.user.name }}</b> -
          <span>{{ [comment.updated_at] | moment("DD MMM YYYY") }}</span
          ><br />
        </div>
        <div v-else>
          <b>{{ $t('anonym') }}</b> -
          <span>{{ [comment.updated_at] | moment("DD MMM YYYY") }}</span
          ><br />
        </div>
        <v-rating
          v-model="comment.rating"
          background-color="gray"
          color="yellow accent-4"
          dense
          size="13"
        ></v-rating>
        <b>{{ comment.title }}</b
        ><br />
        <span>{{ comment.body }}</span
        ><br /><br />
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
export default {
  props: ["comments"],
};
</script>
<style scoped>
.avatar {
  border-radius: 50%;
  width: 48px;
  height: 48px;
  display: block;
  align-content: center;
}
</style>
