<template>
  <table>
    <tr>
      <td>
        <img class="icon" src="@/assets/images/icons/type.png" alt="" />
        {{ $t('type') }} </td>
      <td>{{ post.type.name }}</td>
    </tr>
    <tr>
      <td>
        <img class="icon" src="@/assets/images/icons/location.png" alt="" />
        {{ $t('location') }} </td>
      <td>{{ post.location }}</td>
    </tr>
    <tr>
      <td>
        <img class="icon" src="@/assets/images/icons/required.png" alt="" />
        {{ $t('required') }} </td>
      <td>{{ $t('post-required-person', [post.required]) }}</td>
    </tr>
    <tr>
      <td>
        <img class="icon" src="@/assets/images/icons/duration.png" alt="" />
        {{ $t('duration') }} </td>
      <td>{{ $t('detail-duration', [post.duration]) }}</td>
    </tr>
    <tr>
      <td>
        <img class="icon" src="@/assets/images/icons/start_time.png" alt="" />
        {{ $t('start-time') }} </td>
      <td>{{ post.start_time }}</td>
    </tr>
    <tr>
      <td>
        <img class="icon" src="@/assets/images/icons/cancelation.png" alt="" />
        {{ $t('cancelation') }} </td>
      <td>{{ $t('detail-cancelation', [post.cancelation]) }}</td>
    </tr>
    <tr>
      <td>
        <img class="icon" src="@/assets/images/icons/price.png" alt="" />
        {{ $t('price') }} </td>
      <td>
        {{
          $root.$i18n.locale == "id"
            ? "IDR " : "USD "
        }}
        {{
          $root.$i18n.locale == "id"
            ? post.price_idr : post.price_usd | fm
        }}
      </td>
    </tr>
  </table>
</template>
<script>
export default {
  props: ["post"],
};
</script>
<style>
.icon {
  width: 16px;
  height: 16px;
  margin-right: 12px;
}

table {
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  vertical-align: text-top;
  text-align: left;
  padding: 2px;
  font-size: 14px;
  vertical-align: middle;
  width: 50%;
}
</style>
