<template>
  <v-container grid-list-md text-center pa-0 mb-3>
    <v-layout row wrap align-center>
      <v-flex xs3> {{ $t('5-stars') }} </v-flex>
      <v-flex xs7 pa-0>
        <v-progress-linear
          v-model="five"
          height="10"
          background-color="grey lighten-4"
        ></v-progress-linear>
      </v-flex>
      <v-flex xs2>
        {{ overview.five }}
      </v-flex>
    </v-layout>
    <v-layout row wrap align-center>
      <v-flex xs3> {{ $t('4-stars') }} </v-flex>
      <v-flex xs7 pa-0>
        <v-progress-linear
          v-model="four"
          height="10"
          background-color="grey lighten-4"
        ></v-progress-linear>
      </v-flex>
      <v-flex xs2>
        {{ overview.four }}
      </v-flex>
    </v-layout>
    <v-layout row wrap align-center>
      <v-flex xs3> {{ $t('3-stars') }} </v-flex>
      <v-flex xs7 pa-0>
        <v-progress-linear
          v-model="three"
          height="10"
          background-color="grey lighten-4"
        ></v-progress-linear>
      </v-flex>
      <v-flex xs2>
        {{ overview.three }}
      </v-flex>
    </v-layout>
    <v-layout row wrap align-center>
      <v-flex xs3> {{ $t('2-stars') }} </v-flex>
      <v-flex xs7 pa-0>
        <v-progress-linear
          v-model="two"
          height="10"
          background-color="grey lighten-4"
        ></v-progress-linear>
      </v-flex>
      <v-flex xs2>
        {{ overview.two }}
      </v-flex>
    </v-layout>
    <v-layout row wrap align-center>
      <v-flex xs3> {{ $t('1-stars') }} </v-flex>
      <v-flex xs7 pa-0>
        <v-progress-linear
          v-model="one"
          height="10"
          background-color="grey lighten-4"
        ></v-progress-linear>
      </v-flex>
      <v-flex xs2>
        {{ overview.one }}
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
export default {
  props: ["overview"],
  computed: {
    five: function () {
      return (this.overview.five / this.overview.total) * 100;
    },
    four: function () {
      return (this.overview.four / this.overview.total) * 100;
    },
    three: function () {
      return (this.overview.three / this.overview.total) * 100;
    },
    two: function () {
      return (this.overview.two / this.overview.total) * 100;
    },
    one: function () {
      return (this.overview.one / this.overview.total) * 100;
    },
  },
};
</script>
<style scoped>
.progress-linear {
  margin: 0px;
}
</style>
